import "./App.css";
import React, { useState } from "react";
import {
  Accordion,
  Image,
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

var tickedProblems = []; // All problems ticked by the user in Element 1
var tickedComplexity = []; //All problems ticked by user in Element 2
var tickedComplications = []; // All complications ticked by the user in Element 3

const App = () => {
  const [code, setCode] = useState("00000");
  const [show, setShow] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [isHistorianRequired, setIsHistorianRequired] = useState(false);
  const [isIndependent, setIsIndependent] = useState(false);
  const [isDiscussed, setIsDiscussed] = useState(false);
  let notesReviewed = document.getElementById("notes-reviewed");
  let testsReviewed = document.getElementById("tests-reviewed");
  let testsOrdered = document.getElementById("tests-ordered");
  var codeLevel = 2;
  var debug = true; // Set true for console output

  const handleSubmit = (event) => {
    tickedComplications = [];
    tickedProblems = [];
    tickedComplexity = [];

    // Prevents the page from reloading on submit.
    event.preventDefault();

    // Do nothing if the form is invalid.
    if (event.currentTarget.checkValidity() === false) return;

    /*
		Each element has its own level.
		In order for a code to reach a certain
		level, 2 of the 3 elements need to meet
		that level.
	
		For example, if element 1 is level 5,
		element 2 is level 3, and element 3
		is level 3, then the code will be level 3.
		99203 = level 3, 99205 = level 5
		*/

    let problems = []; // List of all problems in element 1
    let e1Level = 2;
    let e2Level = 2;
    let e3Level = 2;

    /* ELEMENT 1 */
    // Add each ticked problem to an array.
    // Compare each problem's level.
    // Set e1Level to the highest level.

    for (let i = 0; i < 11; i++) {
      problems[i] = document.getElementById("e1-" + i);
      if (problems[i].checked)
        tickedProblems.push(problems[i].getAttribute("data-problem"));
      if (problems[i].checked && parseInt(problems[i].getAttribute("data-level")) > e1Level) {
        e1Level = parseInt(problems[i].getAttribute("data-level"));
      }
    }

    /* ELEMENT 2 */
    // Level 2: default
    // Level 3: 2+ e2Sum or isHistorianRequired
    // Level 4: 1 of the following: 3+ e2Sum or isIndependent or isDiscussed
    // Level 5: 2 of the following: 3+ e2Sum or isIndependent or isDiscussed
    let e2Sum = 0;
    e2Sum += parseInt(notesReviewed.value);
    e2Sum += parseInt(testsReviewed.value);
    e2Sum += parseInt(testsOrdered.value);

    if (isHistorianRequired === "true") {
      e2Level = 3;
      e2Sum++;
    }
    if (e2Sum >= 2) {
      e2Level = 3;
      if (e2Sum >= 3 || isIndependent === "true" || isDiscussed === "true")
        e2Level = 4;
      if (
        (e2Sum >= 3 && isIndependent === "true") ||
        (e2Sum >= 3 && isDiscussed === "true") ||
        (isIndependent === "true" && isDiscussed === "true")
      )
        e2Level = 5;
    } else {
      if (isIndependent === "true" || isDiscussed === "true") e2Level = 4;
      if (isDiscussed === "true" && isIndependent === "true") e2Level = 5;
    }

    if (notesReviewed.value === "1") {
      tickedComplexity.push(
        "1 external note was reviewed."
      );
    }
    else if (notesReviewed.value > "1") {
      tickedComplexity.push(
        notesReviewed.value + " external notes were reviewed."
      );
    }

    if (testsReviewed.value === "1") {
      tickedComplexity.push(
        "1 test was reviewed."
      );
    }
    else if (testsReviewed.value > "1") {
      tickedComplexity.push(
        testsReviewed.value + " tests were reviewed."
      );
    }

    if (testsOrdered.value === "1") {
      tickedComplexity.push(
        "1 test was ordered."
      );
    }
    else if (testsOrdered.value > "1") {
      tickedComplexity.push(
        testsOrdered.value + " tests were ordered."
      );
    }

    if (isHistorianRequired === "true") {
      tickedComplexity.push(
        "An independent historian was required for assessment"
      );
    }
    if (isIndependent === "true") {
      tickedComplexity.push(
        "Independent interpretation of tests were performed by an external provider"
      );
    }
    if (isDiscussed === "true") {
      tickedComplexity.push(
        "There was discussion of management or test interpretation with another provider"
      );
    }

    /* ELEMENT 3 */
    // Similar logic to element 1
    // Add each complication to an array
    // Compare each complication's level
    // Set e3Level to highest level
    let complications = [];
    for (let i = 0; i < 8; i++) {
      complications[i] = document.getElementById("e3-" + i);
      if (complications[i].checked)
        tickedComplications.push(complications[i].getAttribute("data-e3"));
      if (
        complications[i].checked &&
        parseInt(complications[i].getAttribute("data-level")) > e3Level
      ) {
        e3Level = parseInt(complications[i].getAttribute("data-level"));
      }
    }

    /* CODE OUTPUT */
    let a = Math.min(e1Level, e2Level);
    let b = Math.min(e1Level, e3Level);
    let c = Math.min(e2Level, e3Level);
    codeLevel = Math.max(a, b, c);

    switch (codeLevel) {
      default:
        if (isNewPatient === "false") setCode("99212");
        else setCode("99202");
        break;
      case 3:
        if (isNewPatient === "false") setCode("99213");
        else setCode("99203");
        break;
      case 4:
        if (isNewPatient === "false") setCode("99214");
        else setCode("99204");
        break;
      case 5:
        if (isNewPatient === "false") setCode("99215");
        else setCode("99205");
        break;
    }

    // Finally, open the results modal.
    setShow(true);

    if (debug) {
      console.log(
        "Element 1 Level: " +
          e1Level +
          "\nElement 2 Level: " +
          e2Level +
          "\nElement 3 Level: " +
          e3Level +
          "\nCode Level: " +
          codeLevel +
          "\nisNewPatient: " +
          isNewPatient +
          "\nisHistorianRequired: " +
          isHistorianRequired +
          "\nisIndependent: " +
          isIndependent +
          "\nisDiscussed: " +
          isDiscussed
      );

      let consoleStr = "";
      consoleStr += "Ticked Problems:\n";
      for (let i = 0; i < tickedProblems.length; i++)
        consoleStr += "    [" + i + "] " + tickedProblems[i] + "\n";
      consoleStr += "Ticked Complications:\n";
      for (let i = 0; i < tickedComplications.length; i++)
        consoleStr += "    [" + i + "] " + tickedComplications[i] + "\n";
      console.log(consoleStr);
    }
  };

  function finalString() {
    let finalString = "";
  
    for (let i = 0; i < tickedProblems.length; i++) {
      finalString = finalString + tickedProblems[i] + "\n";
    }
  
    for (let i = 0; i < tickedComplexity.length; i++) {
      finalString = finalString + tickedComplexity[i] + "\n";
    }
  
    for (let i = 0; i < tickedComplications.length; i++) {
      finalString = finalString + tickedComplications[i] + "\n";
    }
  
    return finalString;
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Modal.Dialog size="xl" show className="main-form">
              <Modal.Header>
                <Modal.Title as="h1">E/M Code Generator</Modal.Title>
                <Image
                  fluid
                  width={125}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Caduceus.svg/1200px-Caduceus.svg.png"
                />
              </Modal.Header>
              <Form validated onSubmit={handleSubmit}>
                <Modal.Body>
                <Form.Group>
                    <Col>
                      <h5>Is this an established patient?</h5>
                    </Col>
                    <Col>
                      <Form.Check
                        required
                        type="radio"
                        name="newpatient"
                        label="Yes"
                        value={false}
                        onChange={(e) => {
                          setIsNewPatient(e.target.value);
                        }}
                      />
                      <Form.Check
                        required
                        type="radio"
                        name="newpatient"
                        label="No"
                        value={true}
                        onChange={(e) => {
                          setIsNewPatient(e.target.value);
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <hr/>

                  <h2>Element 1</h2>
                  <Form.Text>
                    Number and complexity of problems addressed
                  </Form.Text>
                  <br></br>
                  <Form.Group className="mt-4">
                    <h5>Check all that apply:</h5>
                    <Form.Check
                      className="mt-2"
                      data-level="2"
                      id="e1-0"
                      label="1 self-limited/minor problem"
                      data-problem="1 self-limited/minor problem"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="3"
                      id="e1-1"
                      label="2+ self-limited/minor problems"
                      data-problem="2+ self-limited/minor problems"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="3"
                      id="e1-2"
                      label="1 stable chronic illness"
                      data-problem="1 stable chronic illness"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="3"
                      id="e1-3"
                      label="1 acute uncomplicated injury/illness"
                      data-problem="1 acute uncomplicated injury/illness"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="4"
                      id="e1-4"
                      label="1 new undiagnosed problem of uncertain prognosis"
                      data-problem="1 new undiagnosed problem of uncertain prognosis"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="4"
                      id="e1-5"
                      label="1+ chronic illness with acute exacerbation, progression, or treatment side effect"
                      data-problem="1+ chronic illness with acute exacerbation, progression, or treatment side effect"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="4"
                      id="e1-6"
                      label="2+ stable chronic illnesses"
                      data-problem="2+ stable chronic illnesses"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="4"
                      id="e1-7"
                      label="1 acute illness with systemic symptoms"
                      data-problem="1 illness with systemic symptoms"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="4"
                      id="e1-8"
                      label="1 acute complicated injury"
                      data-problem="1 acute complicated injury"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="5"
                      id="e1-9"
                      label="1+ chronic illness with severe exacerbation, progression, or treatment side effect"
                      data-problem="1+ chronic illness with severe exacerbation, progression, or treatment side effect"
                    />
                    <Form.Check
                      className="mt-2"
                      data-level="5"
                      id="e1-10"
                      label="1 acute or chronic illness or injury that poses threat to life or bodily function"
                      data-problem="1 acute or chronic illness or injury that poses threat to life or bodily function"
                    />
                  </Form.Group>

                  <hr />

                  <h2>Element 2</h2>
                  <Form.Text>
                    Amount and complexity of data to be reviewed and analyzed
                  </Form.Text>
                  <Row className="mt-4">
                    <h4>Category 1A</h4>
                    <Col>
                      <Form.Label>
                        Number of prior external notes reviewed
                      </Form.Label>
                      <Form.Control
                        id="notes-reviewed"
                        type="number"
                        step="1"
                        size="lg"
                        min="0"
                        max="99"
                        placeholder="0"
                      />
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Label>
                        Number of unique tests <b>reviewed</b>
                      </Form.Label>
                      <Form.Text>
                         &nbsp;&nbsp;(not separately reported)
                      </Form.Text>
                      <Form.Control
                        id="tests-reviewed"
                        type="number"
                        step="1"
                        size="lg"
                        min="0"
                        max="99"
                        placeholder="0"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>
                        Number of unique tests <b>ordered</b>
                      </Form.Label>
                      <Form.Text>
                         &nbsp;&nbsp;(not separately reported)
                      </Form.Text>
                      <Form.Control
                        id="tests-ordered"
                        type="number"
                        step="1"
                        size="lg"
                        min="0"
                        max="99"
                        placeholder="0"
                      />
                    </Col>
                  </Row>
                  <Form.Group as="row">
                    <h4 className="mt-4">Category 1B</h4>
                    <Col>
                      <Form.Label>
                        Independent historian required for assessment?
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Check
                        required
                        type="radio"
                        name="cat1b"
                        label="Yes"
                        value={true}
                        onChange={(e) => {
                          setIsHistorianRequired(e.target.value);
                        }}
                      />
                      <Form.Check
                        required
                        type="radio"
                        name="cat1b"
                        label="No"
                        value={false}
                        onChange={(e) => {
                          setIsHistorianRequired(e.target.value);
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as="row">
                    <h4 className="mt-4">Category 2</h4>
                    <Col>
                      <Form.Label>
                        Independent interpretation of tests performed by
                        external provider?
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Check
                        required
                        type="radio"
                        name="cat2"
                        label="Yes"
                        value={true}
                        onChange={(e) => {
                          setIsIndependent(e.target.value);
                        }}
                      />
                      <Form.Check
                        required
                        type="radio"
                        name="cat2"
                        label="No"
                        value={false}
                        onChange={(e) => {
                          setIsIndependent(e.target.value);
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as="row">
                    <h4 className="mt-4">Category 3</h4>
                    <Col>
                      <Form.Label>
                        Discussion of management or test interpretation with
                        another provider?
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Check
                        required
                        type="radio"
                        name="cat3"
                        label="Yes"
                        value={true}
                        onChange={(e) => {
                          setIsDiscussed(e.target.value);
                        }}
                      />
                      <Form.Check
                        required
                        type="radio"
                        name="cat3"
                        label="No"
                        value={false}
                        onChange={(e) => {
                          setIsDiscussed(e.target.value);
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <hr />

                  <h2>Element 3</h2>
                  <Form.Text>
                    Risks of complications and/or morbidity or mortality of
                    patient management.
                  </Form.Text>
                  <h5 className="mt-4">Check all that apply:</h5>
                  <Form.Check
                    data-level="3"
                    id="e3-0"
                    label="OTC medication was prescribed"
                    data-e3="OTC medication was prescribed"
                  />
                  <Form.Check
                    className="mt-2"
                    data-level="3"
                    id="e3-1"
                    label="Conservative treatment was recommended"
                    data-e3="Conservative treatment was recommended"
                  />
                  <Form.Check
                    className="mt-2"
                    data-level="4"
                    id="e3-2"
                    label="Prescription drug management was provided"
                    data-e3="Prescription drug management was provided"
                  />
                  <Form.Check
                    className="mt-2"
                    data-level="4"
                    id="e3-3"
                    label="Tests were ordered"
                    data-e3="Tests were ordered"
                  />
                  <Form.Check
                    className="mt-2"
                    data-level="4"
                    id="e3-4"
                    label="A decision was made for a minor procedure or procedure risk factors were discussed"
                    data-e3="A decision was made for a minor procedure or procedure risk factors were discussed"
                  />
                  <Form.Check
                    className="mt-2"
                    data-level="4"
                    id="e3-5"
                    label="Diagnosis was given with a (Z.55-65) social determinant of health"
                    data-e3="Diagnosis was given with a social determinant of health"
                  />
                  <Form.Check
                    className="mt-2"
                    data-level="5"
                    id="e3-6"
                    label="Long or short term Rx management requiring extensive test monitoring for toxicity was implemented"
                    data-e3="Long or short term Rx management requiring extensive test monitoring for toxicity was implemented"
                  />
                  <Form.Check
                    className="mt-2"
                    data-level="5"
                    id="e3-7"
                    label="A decision regarding the need for a major surgery, need for emergency care, or need for hospitalization was made"
                    data-e3="A decision regarding the need for a major surgery, need for emergency care, or need for hospitalization was made"
                  />

                </Modal.Body>

                <Modal.Footer className="justify-content-center">
                  <Button size="lg" className="w-100" type="submit">
                    Generate Code
                  </Button>
                </Modal.Footer>
              </Form>
              <Form>
                <Modal.Footer className="justify-content-center">
                  <Form.Group>
                    <Form.Label>E/M Code</Form.Label>
                    <Form.Control
                      size="lg"
                      id="code"
                      type="number"
                      readOnly
                      value={code}
                      placeholder="00000"
                    ></Form.Control>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please complete all required fields!
                    </Form.Control.Feedback>
                  </Form.Group>
                </Modal.Footer>
              </Form>
            </Modal.Dialog>
          </Col>
        </Row>
      </Container>

      {/* Results View Modal */}
      <Modal
        show={show} 
        centered
        className="modal-border"
      >
        <Modal.Header className="justify-content-center">
          <h1>Submission Results</h1>
        </Modal.Header>
        <Modal.Body>
          <h5>Element 1</h5>
          <ul>
            {tickedProblems.length > 0 ? (
              tickedProblems.map((problem, index) => (
                <li key={index}>{problem}</li>
              ))
            ) : (
              <li>Nothing to report.</li>
            )}
          </ul>
          <hr />
          <h5>Element 2</h5>
          <ul>
          {tickedComplexity.length > 0 ? (
            tickedComplexity.map((problem, index) => (
              <li key={index}>{problem}</li>
            ))
          ) : (
            <li>Nothing to report.</li>
          )}
          </ul>
          <hr />
          <h5>Element 3</h5>
          <ul>
            {tickedComplications.length > 0 ? (
              tickedComplications.map((problem, index) => (
                <li key={index}>{problem}</li>
              ))
            ) : (
              <li>Nothing to report.</li>
            )}
          </ul>
          <Row className="justify-content-center">
            { tickedComplexity.length === 0 && tickedProblems.length === 0 ? null : (
              <>
                <Button
                  className="w-75"
                  size="lg"
                  variant="primary"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      finalString()
                    )
                  }
                >
                  Copy Report to Clipboard
                </Button>
              </>
            )}
          </Row>
          <hr />
          <h3 className="code-text">Generated Code: {code}</h3>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" size="lg" onClick={() => setShow(false)}>
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default App;